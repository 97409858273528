.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}

.header h1 {
    text-align: center;
}

.homecontainer {
    padding-top: 3rem;
    text-align: center;
}

.homecontainer img {
    height: 20rem;
    margin: auto;
    border-radius: 2%;
    border: 2px solid black;
}

.aboutcontainer {
    padding-top: 8rem;
    text-align: left;
}

.card {
    margin: auto;
    width: 15rem;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 4px
}

.card h3{
    margin: 0;
    padding: 0;
}

.card h4 {
    margin: 0;
}

.card p {

}

.card ul {
    list-style: none;
}

.projectscontainer {
    display: block;
    padding-top: 6rem;
    text-align: center;
}

.projectscontainer img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    height: 15rem;
    width: 30rem;
    border: 2px solid black;
    border-radius: 2%;
    box-shadow: 2px 2px 2px 1px black ;
    margin: auto;
}


.projectscontainer a {
    display: inline-block;
    margin: auto;
    padding-top: 20px;
}

.nav {
    display: flex;
    justify-content: space-around;
    border: 2px solid black;
    padding: 8px;
    width: 90%;
    margin: auto;
    border-radius: 2%;
}

.footer {
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    align-content: center;
    padding-bottom: 10px;
    padding-top: 3rem;
}